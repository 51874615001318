import React from 'react';
import './gardenofpeace.css';

const PopUp = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="close" onClick={onClose}>&times;</button>
        <h5>As-Salamu Alaikum,</h5>
        <p>
          Garden of Peace, the largest Muslim cemetery in the Bay Area, Alhamdulillah, is hosting an <b>Open House at Santa Nella on Sunday, October 27th.</b>
        </p>
        <p>
          We warmly invite you to join us to learn more about the funeral process and our cemetery project. The event will take place on <b>Sunday, October 27th, from 9:00 AM until Dhuhr.</b> A complimentary brunch will be provided, Insha’Allah.
        </p>
        <p>
          Please kindly confirm your attendance and let us know if you will be able to join us.
        </p>
        <p>
          The Garden of Peace project, spearheaded by the Bay Area Muslim community, has secured 160 acres of land in Santa Nella, CA, for a community cemetery. We are working diligently to establish the property for burials by January 2025, Inshallah. However, to make this a reality, we require an additional $300K in funding. These funds are vital for essential improvements such as road pavement, land development, gate and fence installation, and other necessary developments. Your continued support is invaluable.
        </p>
        <p>
          <b>Alhamdulillah, we have reopened the <a href="http://www.gardenofpeaceus.org/burialplotpledgeform">Burial Plot Pledge Form</a> to secure additional funding.</b> This form will remain open until the end of October, and we encourage you to contribute and secure your spot in this community cemetery project. Your generous donations will help us meet our financial targets and bring us closer to making this cemetery a reality, Inshallah.
        </p>
        <p>
          <b>Contributions can also be made via check to the PO Box address at 3216, Santa Clara, CA 95055, or electronically using Zelle at gardenofpeace.usa@gmail.com.</b> Your generous donations will not only help us meet our financial targets but also play a significant role in the success of this noble community initiative.
        </p>
        <p>
          <b>Additionally, we are seeking dedicated and compassionate <a href="http://www.gardenofpeaceus.org/volunteering">volunteers</a> to join us in supporting bereaved family members during the funeral and burial process.</b> Guidance and training will be provided to all interested volunteers.
        </p>
        <p>
          For more information, please contact us at:
          <br />
          Phone Number: (669) 214-6771
          <br />
          Email: gardenofpeace.usa@gmail.com
          <br />
          Website: <a href="http://www.gardenofpeaceus.org">www.gardenofpeaceus.org</a>
        </p>
        <p>
          Jazakallahu Khairan,
          <b> Garden of Peace Team</b>
        </p>
      </div>
    </div>
  );
};

export default PopUp;
